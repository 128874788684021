import React from "react";

import { record } from "aws-amplify/analytics";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import { DefaultSearchBox } from "./DefaultSearchBox";
import { HomeFooter } from "./HomeFooter";
import { AgenciesList } from "./AgenciesList";
// import { RecentlyAccessStops } from "./RecentlyAccessStops";

function Home() {
  // aws-amplify/analitycs
  record({
    name: "homeVisit",
  });

  // const [stops, setStops] = useState();

  // useEffect(() => {
  //   getStopsData()
  //     .then((value) => {
  //       setStops(value);
  //     })
  //     .catch((e) => console.log(e));
  // }, []);

  return (
    <>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            バス運行情報
          </Typography>
          <Box sx={{ m: 2 }}>
            <DefaultSearchBox />
          </Box>
          {/* {stops && <RecentlyAccessStops stops={stops} />} */}
          <AgenciesList />
        </Box>
      </Container>
      <Box sx={{ my: 3 }}>
        <Container maxWidth="xs">
          <HomeFooter></HomeFooter>
        </Container>
      </Box>
    </>
  );
}
export default Home;
