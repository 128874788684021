import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

export function DelayAlert(props) {
  const delays = props.delays;
  if (!delays) return;
  if (!Array.isArray(delays)) return;
  if (delays.length === 0) return;
  const delayMax = delays.reduce((a, b) => Math.max(a, b), -Infinity);
  if (delayMax === 0) return; // No Alert
  const delayMin = delays.reduce((a, b) => Math.min(a, b), Infinity);
  // severity
  let severity = "info";
  if (delayMax > 4) severity = "warning";
  if (delayMax > 9) severity = "error";

  if (delayMin === delayMax) {
    return (
      <Stack sx={{ m: 1 }}>
        <Alert severity={severity}>約{delayMax}分ほど遅れています</Alert>
      </Stack>
    );
  }
  return (
    <Stack sx={{ m: 1 }}>
      <Alert severity={severity}>
        約{delayMin}～{delayMax}分ほど遅れています
      </Alert>
    </Stack>
  );
}
