import Box from "@mui/material/Box";

import { getStopTimesAnchorIndex } from "../Common/CommonFunc";

import { FilteredCardList } from "./FilteredCardList";
import { FilteredWithDelayedStopTimes } from "./FilteredWithDelayedStopTimes";

export function FilteredStopTimes(props) {
  const NUM = 5;
  const stopTimes = props.stopTimes;
  const len = stopTimes.length;
  const page = props.page;
  const idx =
    typeof page === "number" ? page : getStopTimesAnchorIndex(stopTimes);
  const end = idx + NUM > len ? len : idx + NUM;
  const filtered = stopTimes.slice(idx, end);

  // 固定ページ（リアルタイム以外）を表示
  if (page !== null) {
    return (
      <Box
        sx={{
          m: 1,
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill,minmax(320px,1fr))",
          gap: 1,
        }}
      >
        <FilteredCardList stopTimes={filtered} />
      </Box>
    );
  }

  return <FilteredWithDelayedStopTimes stopTimes={stopTimes} idx={idx} />;
}
