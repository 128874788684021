import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { generateClient } from "aws-amplify/api";
import { getStops } from "../../graphql/queries";
import { record } from "aws-amplify/analytics";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { CommonFooter } from "../Common/CommonFooter";
import { setMetaOgTitle } from "../Common/CommonFunc";
import { HideBackHeader } from "../Common/HideBackHeader";
import { NearbyStops } from "./NearbyStops";
import { StopInfo } from "./StopInfo";

export default function Place() {
  const { stopId } = useParams();
  const [stop, setStop] = useState();
  const [errorStatus, setErrorStatus] = useState(false);

  const client = generateClient();

  async function fetchData(stopId) {
    // Stop
    const value = await client.graphql({
      query: getStops,
      variables: { stop_id: stopId },
    });
    if (!value.data.getStops) {
      setErrorStatus(true);
      return;
    }
    const stop = value.data.getStops;
    setStop(stop);

    // stop name
    const stopName = stop.platform_code
      ? stop.stop_name + " のりば" + stop.platform_code
      : stop.stop_name;

    // title
    const title =
      stopName + " " + stop.stop_lat + "," + stop.stop_lon + " バス停周辺情報";
    document.title = title;

    // og:title
    setMetaOgTitle(title);

    // description
    const description =
      stopName +
      " 緯度" +
      stop.stop_lat +
      " 経度" +
      stop.stop_lon +
      " バス停の周辺情報です";

    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", description);

    // aws-amplify/analitycs
    record({
      name: "placeVisit",
      attributes: { id: stop.stop_id },
    });
  }

  useEffect(() => {
    fetchData(stopId);
  }, []);

  return (
    <>
      {stop && <HideBackHeader stop={stop}></HideBackHeader>}
      <main>
        {errorStatus && (
          <Typography sx={{ m: 2 }}>バス停が見つかりません😔</Typography>
        )}
        {stop && (
          <Box sx={{ p: 1 }}>
            <StopInfo stop={stop} key={stop.stop_id} />
          </Box>
        )}
        <Typography component="h2" sx={{ mt: 2, mx: 1 }}>
          近くのバス停
        </Typography>
        {stop && stop.nearby && stop.nearby.length > 0 && (
          <NearbyStops stop={stop} />
        )}
      </main>
      <Box sx={{ my: 2 }}>
        <CommonFooter></CommonFooter>
      </Box>
    </>
  );
}
