import { useState, useEffect } from "react";
import { generateClient } from "aws-amplify/api";

import { getBatchTrips, getBatchRoutes } from "../../graphql/queries";

import { CardList } from "./CardList";

export function FilteredCardList(props) {
  const stopTimes = props.stopTimes;
  const client = generateClient();
  const [tripMap, setTripMap] = useState();
  const [routeMap, setRouteMap] = useState();

  async function fetchData(tripIds) {
    // trips
    const tMap = new Map();
    const t = await client.graphql({
      query: getBatchTrips,
      variables: { ids: tripIds },
    });
    for (const tData of t.data.getBatchTrips) {
      tMap.set(tData.trip_id, tData);
    }
    // routes
    const rSet = new Set();
    const rMap = new Map();
    for (const key of tMap.keys()) {
      rSet.add(tMap.get(key).route_id);
    }
    const r = await client.graphql({
      query: getBatchRoutes,
      variables: { ids: Array.from(rSet) },
    });
    const routes = r.data.getBatchRoutes;
    for (const route of routes) {
      rMap.set(route.route_id, route);
    }
    setTripMap(tMap);
    setRouteMap(rMap);
  }

  // trips
  const tripSet = new Set();
  for (const stopTime of stopTimes) {
    tripSet.add(stopTime.trip_id);
  }
  const tripIds = Array.from(tripSet);

  useEffect(() => {
    if (tripIds.length > 0) {
      fetchData(tripIds);
    }
  }, []);

  return (
    <>
      {routeMap && tripMap && (
        <CardList stopTimes={stopTimes} tripMap={tripMap} routeMap={routeMap} />
      )}
    </>
  );
}
