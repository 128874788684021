import { useLocation } from "react-router-dom";

import Button from "@mui/material/Button";
import UpdateIcon from "@mui/icons-material/Update";
import Stack from "@mui/material/Stack";

export function BeforeAndAfter(props) {
  const location = useLocation();
  if (!Number.isInteger(props.idx)) return;
  const idx = props.idx;
  const len = props.len;
  const NUM = props.num;

  // prev
  const prev = idx - NUM < 0 ? 0 : idx - NUM;
  const prevDisabled = idx === 0 ? true : false;
  const prevCount = idx > NUM ? NUM : idx;

  // next
  let nextCount = idx + NUM + NUM < len ? NUM : len - idx - NUM;
  let nextDisabled = false;
  if (nextCount < 0) nextCount = 0;
  if (nextCount < 1) nextDisabled = true;

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        justifyContent: "space-around",
        alignItems: "center",
        my: 1,
      }}
    >
      <Button
        href={location.pathname + "?page=" + prev}
        disabled={prevDisabled}
        // startIcon={<ArrowBackIcon />}
      >
        前の{prevCount}便
      </Button>
      <Button
        variant="contained"
        href={location.pathname}
        startIcon={<UpdateIcon />}
      >
        リアルタイム
      </Button>
      <Button
        // variant="outlined"
        href={location.pathname + "?page=" + (idx + NUM)}
        disabled={nextDisabled}
        // endIcon={<ArrowForwardIcon />}
      >
        次の{nextCount}便
      </Button>
    </Stack>
  );
}
