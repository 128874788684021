import { record } from "aws-amplify/analytics";

import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import { CommonFooter } from "../Common/CommonFooter";

export default function AdminInfo() {
  // title
  const title = "運営者情報 - t2.mame2.link";
  document.title = title;

  // description
  document
    .querySelector('meta[name="description"]')
    .setAttribute("content", title);

  // aws-amplify/analitycs
  record({
    name: "adminInfoVisit",
  });

  return (
    <>
      <main>
        <Typography component="h1" variant="h5" sx={{ m: 1 }}>
          運営者情報
        </Typography>
        <Typography sx={{ m: 1 }}>
          このWebサイトは日本で働くシステムエンジニア「mame2」が個人で運営しています。
          <Link href="https://www.odpt.org/">
            公共交通オープンデータセンター
          </Link>
          を使い
          <Link href="https://www.kotsu.metro.tokyo.jp/bus/">都営バス</Link>
          の運行情報を表示しています。
        </Typography>
      </main>
      <CommonFooter></CommonFooter>
    </>
  );
}
