import React, { useState } from "react";
import PropTypes from "prop-types";
import { /*useNavigate,*/ useLocation } from "react-router-dom";

import useScrollTrigger from "@mui/material/useScrollTrigger";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
// import HomeIcon from "@mui/icons-material/Home";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import NearMeIcon from "@mui/icons-material/NearMe";
import MapIcon from "@mui/icons-material/Map";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Slide from "@mui/material/Slide";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";

import { BusStopIcon } from "./BusStopIcon";
import { Logo1Icon } from "./Logo1Icon";
import { LineIcon } from "../Common/CommonFunc";

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export function HideHomeHeader(props) {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const stop = props.stop;

  const stopUrl = "/stop/" + stop.stop_id;
  let stopName = stop.stop_name;
  if (stop.platform_code) stopName += " " + stop.platform_code;

  const searchUrl = "/search";

  const lineTargetUrl = "https://t2.mame2.link" + location.pathname; // + location.search;
  const sendByLineUrl =
    "https://line.me/R/msg/text/?" + encodeURIComponent(lineTargetUrl);

  const latlng = stop.stop_lat + "," + stop.stop_lon;
  let appleDevice = false;
  const ua = window.navigator.userAgent.toLowerCase();
  if (
    ua.indexOf("iphone") !== -1 ||
    ua.indexOf("ipad") !== -1 ||
    ua.indexOf("mac os x") !== -1
  ) {
    appleDevice = true;
  }
  let map_url = "https://www.google.com/maps/search/?api=1&query=";
  if (appleDevice) {
    map_url = "https://maps.apple.com/?q=";
  }
  const device_map_url = map_url + latlng;

  const placeUrl = "/place/" + stop.stop_id;

  const localtheme = createTheme({
    palette: {
      primary: { main: "#f5f5f5" },
    },
  });

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <ThemeProvider theme={localtheme}>
      <HideOnScroll {...props}>
        <AppBar elevation={0}>
          <Toolbar>
            <IconButton
              size="large"
              color="inherit"
              href="/"
              aria-label="ホームに移動"
            >
              <Logo1Icon />
            </IconButton>
            <Box sx={{ flexGrow: 1 }} />
            <IconButton
              size="large"
              color="inherit"
              edge="end"
              aria-label="open drawer"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
      <Drawer open={open} onClose={toggleDrawer(false)} anchor="right">
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={toggleDrawer(false)}
        >
          <List>
            <ListItem key="home" disablePadding>
              <ListItemButton href="/">
                <ListItemIcon>
                  <Logo1Icon />
                </ListItemIcon>
                <ListItemText primary="バス運行情報" />
              </ListItemButton>
            </ListItem>
            <ListItem key="stop" disablePadding>
              <ListItemButton href={stopUrl}>
                <ListItemIcon>
                  <BusStopIcon />
                </ListItemIcon>
                <ListItemText primary={stopName} />
              </ListItemButton>
            </ListItem>
            <ListItem key="search" disablePadding>
              <ListItemButton href={searchUrl}>
                <ListItemIcon>
                  <SearchIcon />
                </ListItemIcon>
                <ListItemText primary="バス停検索" />
              </ListItemButton>
            </ListItem>
            <ListItem key="map" disablePadding>
              <ListItemButton href={device_map_url}>
                <ListItemIcon>
                  <MapIcon />
                </ListItemIcon>
                <ListItemText primary="地図" />
              </ListItemButton>
            </ListItem>
            <ListItem key="nearby" disablePadding>
              <ListItemButton href={placeUrl}>
                <ListItemIcon>
                  <NearMeIcon />
                </ListItemIcon>
                <ListItemText primary="周辺情報" />
              </ListItemButton>
            </ListItem>
            <ListItem key="sendByLine" disablePadding>
              <ListItemButton href={sendByLineUrl}>
                <ListItemIcon>
                  <LineIcon />
                </ListItemIcon>
                <ListItemText primary="Lineで送る" />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </ThemeProvider>
  );
}
