export const agencies = new Map()
  .set("8000020130001", {
    agency_id: "8000020130001",
    agency_name: "都営バス",
    agency_url: "https://www.kotsu.metro.tokyo.jp/bus/",
    agency_color: "#227324",
  })
  .set("7000020141305", {
    agency_id: "7000020141305",
    agency_name: "川崎市バス",
    agency_url: "https://www.city.kawasaki.jp/820/",
    agency_color: "#00789F",
  });
