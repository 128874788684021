import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";

import { generateClient } from "aws-amplify/api";
import { getStops } from "../../graphql/queries";
import { record } from "aws-amplify/analytics";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { CommonFooter } from "../Common/CommonFooter";
import {
  toHalfWidth,
  setLinkCanonical,
  setMetaOgTitle,
} from "../Common/CommonFunc";
import { StopName } from "./StopName";
import { BusStopSchema } from "./BusStopSchema";
import { StopTimes } from "./StopTimes";
import { HideHomeHeader } from "../Common/HideHomeHeader";
import { agencies } from "../Common/Agencies";

export default function Stop() {
  const { stopId } = useParams();
  const [stop, setStop] = useState();
  const [errorStatus, setErrorStatus] = useState(false);

  const client = generateClient();
  const MAX_HEADSING = 3;

  // location query
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageStr = queryParams.get("page");
  const page = pageStr === null ? null : parseInt(pageStr);

  let description = "#busStopの時刻表です。";

  async function fetchData(stopId) {
    // Stop
    const value = await client.graphql({
      query: getStops,
      variables: { stop_id: stopId },
    });
    if (!value.data.getStops) {
      setErrorStatus(true);
      return;
    }
    const stop = value.data.getStops;
    setStop(stop);

    // stopName
    let stopName = stop.stop_name;
    if (stop.platform_code) stopName += " " + stop.platform_code; // のりば

    let sub = ""; // " -";
    let i = 0;
    let shortSub = "";
    const aSet = new Set();
    const rSet = new Set();
    const hSet = new Set();
    for (const headsign of stop.headsigns) {
      if (i >= MAX_HEADSING) break;
      const agencyName = agencies.get(headsign.agency_id)?.agency_name;
      const routeShortName = headsign.route_short_name;
      const stopHeadsign = headsign.headsign;
      aSet.add(agencyName);
      rSet.add(routeShortName);
      hSet.add(stopHeadsign);
      sub = sub + " " + routeShortName + " " + stopHeadsign;
      if (i === 0) {
        shortSub = sub + " " + agencyName;
      }
      i++;
    }
    // if (stop.headsigns.length > MAX_HEADSING) sub += " など";
    sub += " " + Array.from(aSet).toString();

    // title
    let title = toHalfWidth(stopName + shortSub);
    // if (Number.isInteger(page)) title += " " + page + "便";
    document.title = title;

    // og:title
    setMetaOgTitle(title);

    // description
    stopName = stop.stop_name;
    if (stop.platform_code) stopName += " " + stop.platform_code;
    // const description = toHalfWidth(stopName + sub);
    description = description.replace("#busStop", stopName);
    if (rSet.size > 0) {
      description +=
        "系統番号は " + Array.from(rSet).toString().replaceAll(",", " ") + " ";
    }
    if (hSet.size > 0) {
      description +=
        "行先は " + Array.from(hSet).toString().replaceAll(",", " ") + " ";
    }
    if (aSet.size > 0) {
      description +=
        "運行会社は" +
        Array.from(aSet).toString().replaceAll(",", " ") +
        "です。";
    }
    description = toHalfWidth(description);

    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", description);

    // canonical
    const canonical_url = "https://t2.mame2.link/stop/" + stop.stop_id;
    setLinkCanonical(canonical_url);

    // aws-amplify/analitycs
    record({
      name: "stopVisit",
      attributes: { id: stop.stop_id },
    });
  }

  useEffect(() => {
    fetchData(stopId);

    // indexedDB
    // addStopId(stopId);
  }, []);

  return (
    <>
      {stop && <HideHomeHeader stop={stop} />}
      <main>
        {errorStatus && (
          <Typography sx={{ m: 2 }}>バス停が見つかりません😔</Typography>
        )}
        {stop && <StopName stop={stop} />}
        {stop && <StopTimes stop={stop} page={page} />}
      </main>
      <Box sx={{ my: 2 }}>
        <CommonFooter></CommonFooter>
      </Box>
      {stop && <BusStopSchema stop={stop} />}
    </>
  );
}
