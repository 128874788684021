import { record } from "aws-amplify/analytics";

import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

export default function NotFound() {
  // title
  const title = "404 ページがありません🥹 - t2.mame2.link";
  document.title = title;

  // description
  document
    .querySelector('meta[name="description"]')
    .setAttribute("content", title);

  // aws-amplify/analitycs
  record({
    name: "notFoundVisit",
  });
  return (
    <>
      <main>
        <Typography sx={{ m: 1 }}>404 ページがありません🥹</Typography>
        <Link href="/" sx={{ m: 1 }}>
          ホームへ行く
        </Link>
      </main>
    </>
  );
}
